<template>
  <div class="main-top">
    <div class="top-bg">
      <img class="bg" :src="require(`@/assets/images/bg.png`)" alt="" />
      <div class="main-con">
        <h1>承诺达标合格证</h1>
        <div class="identifying">
          <img
            style="width: 22px; height: 28px"
            :src="require(`@/assets/images/ywym.png`)"
            alt=""
          />
          <span>一物一码</span>
          <img
            style="width: 26px; height: 23px"
            :src="require(`@/assets/images/smyz.png`)"
            alt=""
          />
          <span>扫码验真</span>
          <img
            style="width: 26px; height: 26px"
            :src="require(`@/assets/images/jjjh.png`)"
            alt=""
          />
          <span>拒绝假货</span>
        </div>
      </div>
    </div>
    <div v-if="defaultPageShow" class="tab-con">
      <div class="tab-main">
        <van-tabs
          @click="onClick"
          v-model="active"
          :before-change="beforeChange"
          swipe-threshold="1"
          animated
        >
          <van-tab title="电子合格证">
            <electron
              :data="data"
              :basisList="basisList"
              :contentList="contentList"
              :defaultPageShow="defaultPageShow"
            />
          </van-tab>
          <van-tab title="企业展示">
            <enterprise
              :createUserName="data.createUserName"
              :createCertPhone="data.createCertPhone"
              :subjectId="subjectId"
              ref="enterprise"
            />
          </van-tab>

          <van-tab title="生产信息">
            <produce
              :productBatchId="productBatchId"
              :certificateId="certificateId"
              :unitList="unitList"
              ref="child"
            />
          </van-tab>
          <van-tab title="检测信息">
            <detection
              :productBatchId="productBatchId"
              :certificateId="certificateId"
            />
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div v-if="!defaultPageShow" class="default-con">
      <div class="active-con">
        <img :src="require(`@/assets/images/ksj.png`)" alt="" />
        <span>该码暂未激活 </span>
      </div>
    </div>
    <div v-if="isCodeShow" @click="codeHand()" class="code-con">
      <img :src="require(`@/assets/images/cx.png`)" alt="" />
      <h1>防伪码查询</h1>
    </div>
  </div>
</template>

<script>
import electron from "@/components/electron/index.vue";
import enterprise from "@/components/enterprise/index.vue";
import fromInfo from "@/components/from/index.vue";
import produce from "@/components/produce/index.vue";
import supervise from "@/components/supervise/index.vue";
import detection from "@/components/detection/index.vue";
import cateApi from "@/request/api/hegezhen";
import axios from "axios";
export default {
  components: {
    electron,
    enterprise,
    fromInfo,
    produce,
    supervise,
    detection,
  },
  data() {
    return {
      active: 0,

      list: [
        {
          id: 1,
          name: "电子合格证",
        },
        {
          id: 2,
          name: "企业展示",
        },
        {
          id: 3,
          name: "追溯信息",
        },
        {
          id: 4,
          name: "生产信息",
        },
        {
          id: 5,
          name: "监管信息",
        },
        {
          id: 6,
          name: "检测信息",
        },
        {
          id: 7,
          name: "防伪码查询",
        },
      ],
      data: {},
      basisList: [],
      contentList: [],
      unitList: [],
      subjectList: [],
      tradeList: [],
      certificateId: null,
      subjectId: null,
      productBatchId: null,
      labId: "",
      isCodeShow: false,
      certificateCode: "", //编码
      basis: "",
      weigntunitList: [],
      nationalCode: "",
      defaultPageShow: true,
    };
  },

  async mounted() {
    let item = this.$route.query;
    this.certificateId = item.certId;
    this.labId = item.labId;
    await this.dictionary();
    if (item.labId) {
      // 说明是扫的防伪码进来
      this.isCodeShow = true;
      try {
        let params = {
          labId: item.labId,
        };
        this.$global.loading.show();
        let response = await cateApi.bianm(params);
        this.$global.loading.hide();
        let data = response.data;
        if (!data) {
          this.defaultPageShow = false;
          return;
        }
        if (data.whetherSecurityCode == 1) {
          this.isCodeShow = true;
        }
        this.getData(data);
        //访问量
        try {
          let params = {
            certificateId: data.certificateId,
            queryType: "扫码查询",
            querySource: "1",
            requsest: "",
            codeLabelId: item.labId,
          };
          this.$global.loading.show();
          let response = await cateApi.jilcs(params);
          this.$global.loading.hide();
        } catch (error) {
          this.$global.loading.hide();
          console.log(error);
          this.$toast(error.msg);
        }
      } catch (error) {
        this.$global.loading.hide();
        console.log(error);
        this.$toast(error.msg);
      }
    }
    if (item.certId) {
      // 说明是扫的合格证二维码进来
      try {
        let params = {
          certificateId: item.certId,
        };
        this.$global.loading.show();
        let response = await cateApi.cateId(params);
        this.$global.loading.hide();
        let data = response.data;
        this.getData(data);
        console.log("data is ", this.data);
      } catch (error) {
        this.$global.loading.hide();
        console.log(error);
      }
      //说明是app端进来 要去记录扫描次数
      if (!item.pc) {
        try {
          let params = {
            certificateId: item.certId,
            queryType: "扫码查询",
            querySource: "1",
            requsest: "",
          };
          this.$global.loading.show();
          let response = await cateApi.jilcs(params);
          this.$global.loading.hide();
          console.log(response.data);
        } catch (error) {
          this.$global.loading.hide();
          console.log(error);
        }
      }
    }
    try {
      axios({
        url: `https://qifu-api.baidubce.com/ip/geo/v1/district?ip=39.144.219.189`,
        timeout: 1500000, //超时时间设置，单位毫秒
        type: "get",
        success: function (response) {
          console.log("百度", response.data);
        },
        fail: function (err) {
          console.log(err);
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async dictionary() {
      await Promise.all([
        this.getaccord(),
        this.getContent(),
        this.getunit(),
        this.getweigntunit(),
        // this.getLocation(),
      ]);
    },
    getLocation() {
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 10000, // 设置定位超时时间，默认：无穷大
          // offset: [10, 20], // 定位按钮的停靠位置的偏移量
          // zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          // position: "RB", //  定位按钮的排放位置,  RB表示右下
        });
        geolocation.getCurrentPosition(async function (status, result) {
          if (status == "complete") {
            console.log("定位成功");
            let data = result;
            console.log(data);
            console.log(data.position.lat); //纬度
            console.log(data.position.lng); //经度
            try {
              axios({
                url: `https://qifu-api.baidubce.com/ip/geo/v1/district?${data.position.lat}${data.position.lng}`,
                timeout: 1500000, //超时时间设置，单位毫秒
                type: "get",
                success: function (response) {
                  console.log(response.data);
                },
                fail: function (err) {
                  console.log(err);
                },
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            // onError(result);
            // 定位出错
            console.log("定位出错");
            console.log(result);
          }
        });
      });
    },
    async getData(data) {
      if (!data) return;
      this.subjectId = data.subjectId;
      this.productBatchId = data.productBatchId;
      this.certificateCode = data.certificateCode;
      this.nationalCode = data.nationalCode;
      await this.getoperatorId(data.productBatchId);
      data.commitmentContent = data.commitmentContent.split(",");
      let commitmentBasis = [];
      if (data.commitmentContent.length == 3) {
        commitmentBasis.push("4");
      }
      if (this.basis.enterprise) {
        //企业自检
        commitmentBasis.push("2");
      }
      if (this.basis.thirdParty) {
        //第三方检测
        commitmentBasis.push("1");
      }
      if (this.basis.supervise) {
        //内部质量控制
        commitmentBasis.push("3");
      }
      data.commitmentBasis = commitmentBasis;
      for (let index = 0; index < this.weigntunitList.length; index++) {
        const element = this.weigntunitList[index];
        if (element.value == data.weightUnit) {
          data.weightUnit = element.label;
        }
      }
      for (let index = 0; index < this.unitList.length; index++) {
        const element = this.unitList[index];
        if (element.value == data.unit) {
          data.unitData = element.label;
        }
      }

      await this.$nextTick();
      this.data = data;
    },
    async getoperatorId(id) {
      try {
        let params = {
          productBatchId: id,
        };
        let response = await cateApi.taskAnd(params);
        this.basis = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    beforeChange(index) {
      this.active = index;
      // 返回 Promise 来执行异步逻辑
      return new Promise((resolve) => {
        // 在 resolve 函数中返回 true 或 false
        resolve(true);
      });
    },
    //获取承诺依据
    async getaccord() {
      try {
        let params = {
          dictType: "commitment_basis",
        };
        this.$global.loading.show();
        let response = await cateApi.dict(params);
        this.$global.loading.hide();
        let list = response.data;

        this.basisList = list;
      } catch (error) {
        this.$global.loading.hide();
      }
    },
    //获取承诺内容
    async getContent() {
      try {
        let params = {
          dictType: "commitment_content",
        };
        this.$global.loading.show();
        let response = await cateApi.dict(params);
        this.$global.loading.hide();
        let list = response.data;

        this.contentList = list;
      } catch (error) {
        this.$global.loading.hide();
      }
    },
    //获取产品单位
    async getunit() {
      try {
        let params = {
          dictType: "count_unit",
        };
        this.$global.loading.show();
        let response = await cateApi.dict(params);
        this.$global.loading.hide();
        let list = response.data;
        this.unitList = list;
      } catch (error) {
        this.$global.loading.hide();
      }
    },
    //获取重量
    async getweigntunit() {
      try {
        let params = {
          dictType: "weight_unit",
        };
        this.$global.loading.show();
        let response = await cateApi.dict(params);
        this.$global.loading.hide();
        let list = response.data;
        this.weigntunitList = list;
      } catch (error) {
        this.$global.loading.hide();
      }
    },
    codeHand() {
      let params = {
        certificateCode: this.certificateCode,
        labId: this.labId,
      };
      params = JSON.stringify(params);
      this.$router.push({
        path: `/model`,
        query: { params },
      });
    },
    async onClick(name) {
      console.log(name);
      await this.$nextTick();
      if (name == 2) {
        this.$refs.child.getDatasc();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-top {
  height: 100%;

  .top-bg {
    position: relative;
    .bg {
      width: 100%;
      height: 5.5rem;
    }
    .main-con {
      position: absolute;
      left: 0.5rem;
      top: 1.28rem;
      h1 {
        font-weight: bold;
        font-size: 0.44rem;
        color: #ffffff;
      }
      .identifying {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.244rem;
        img {
          margin-right: 0.068rem;
        }
        span {
          color: #fff;
          margin-right: 0.18rem;
        }
      }
    }
  }

  .tab-con {
    margin-top: -2.22rem;
    padding: 0 0.28rem;
    position: relative;
    .tab-main {
      width: 100%;
      border-radius: 0.224rem;
      background-color: #fff;
      ::v-deep .van-tabs {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 0.224rem;

        .van-tabs__wrap {
          width: 100%;
          position: relative;
          border-radius: 0.224rem 0.224rem 0px 0px;
          .van-tabs__nav {
            background: #f0f8ee;
            .van-tab {
              span {
                color: #1a211d;
                font-weight: bold;
                font-size: 0.25rem;
              }
            }
            .van-tab--active {
              span {
                color: #5aad61;
                font-weight: bold;
                font-size: 0.25rem;
              }
            }
          }
          .van-tabs__line {
            background-color: #5aad61;
          }
        }
        .van-tabs__content {
          // flex: 1;
          // height: 0;
          position: relative;
          width: 100%;
          // overflow: scroll;
        }
      }
    }
  }
  .default-con {
    margin-top: -111px;
    padding: 0 0.28rem;
    position: relative;
    height: 450px;
    .active-con {
      width: 100%;
      height: 100%;
      border-radius: 0.224rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.12rem;
      padding: 0.28rem;
      box-sizing: border-box;
      img {
        width: 4rem;
        height: 4rem;
      }
      span {
        margin-top: 0.48rem;
        font-size: 0.28rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #142829;
      }
    }
  }
}

.code-con {
  position: absolute;
  right: 0;
  top: 10%;
  transform: translateY(-90%);
  background-color: #5aad61;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 0.9rem;
  border-radius: 0.8rem 0 0 0.8rem;
  width: 2.4rem;
  img {
    width: 0.4rem;
    height: 0.4rem;
  }
  h1 {
    color: #fff;
    margin-left: 0.08rem;
  }
}
</style>
