<template>
  <div>
    <div v-show="show" class="mains-con">
      <div
        v-for="(item, idx) in list"
        :key="idx"
        @click="fromHand(item)"
        class="list-item"
      >
        <div class="item-row">
          <span>生产信息</span>
          <span>以下信息由主体提供</span>
        </div>
        <div class="info">
          <h1>产品种类</h1>
          <h1>{{ item.productClassName }}</h1>
        </div>
        <div class="info">
          <h1>产品名称</h1>
          <h1>{{ item.productName }}</h1>
        </div>
        <div class="info">
          <h1>操作时间</h1>
          <h1>{{ item.operationTime }}</h1>
        </div>
        <div class="info">
          <h1>操作名称</h1>
          <h1>{{ item.operationName }}</h1>
        </div>
        <div class="info">
          <h1>记录类型</h1>
          <h1 v-if="item.recordType == 1">种植信息</h1>
          <h1 v-if="item.recordType == 2">土地信息</h1>
          <h1 v-if="item.recordType == 3">施肥</h1>
          <h1 v-if="item.recordType == 4">灌溉</h1>
          <h1 v-if="item.recordType == 5">收获</h1>
          <h1 v-if="item.recordType == 6">其他</h1>
        </div>
      </div>
    </div>
    <div v-show="!show" class="mains-con inf">
      <div class="list-item">
        <div class="info">
          <h1>创建人</h1>
          <h1>{{ itemData.subjectName }}</h1>
        </div>
        <!-- <div class="info">
          <h1>创建时间</h1>
          <h1>{{ itemData.createTime }}</h1>
        </div> -->
        <div class="info">
          <h1>生产企业</h1>
          <h1>{{ itemData.subjectName }}</h1>
        </div>
        <div class="info">
          <h1>产品类型</h1>
          <h1>{{ itemData.productClassName }}</h1>
        </div>
        <div class="info">
          <h1>产品名称</h1>
          <h1>{{ itemData.productName }}</h1>
        </div>
        <div class="info">
          <h1>所属地区</h1>
          <h1>{{ itemData.fullRegionName }}</h1>
        </div>
        <div class="info">
          <h1>操作名称</h1>
          <h1>{{ itemData.recordType }}</h1>
        </div>
        <!-- <div class="info">
          <h1>操作时间</h1>
          <h1 v-if="itemData.operationTime">
            {{ itemData.operationTime.substring(0, 10) }}
          </h1>
        </div> -->
        <div class="info">
          <h1>操作描述</h1>
          <h1>{{ itemData.operationDes }}</h1>
        </div>
        <div class="info">
          <h1>录音</h1>
          <span v-if="!itemData.operationAudio">无</span>
          <div v-else>
            <audio
              @ended="overAudio"
              ref="audio"
              :src="itemData.operationAudio"
            />
            <div class="video-play">
              <van-button @click="playHand()" v-if="!playShow" type="primary"
                >播放</van-button
              >
              <van-button @click="suspendHand()" v-if="playShow" type="success"
                >暂停</van-button
              >
            </div>
          </div>
        </div>

        <div class="info">
          <h1>视频</h1>
          <span v-if="!itemData.operationVideo">无</span>
          <video
            v-else
            ref="videoPlayer"
            class="video video-js"
            id="video"
            controls=""
            autoplay="true"
            loop=""
            muted="muted"
            x5-video-player-type="h5"
            x5-vide=""
            o-player-fullscreen="true"
            preload="auto"
            webkit-playsinline="true"
            playsinline="true"
            :src="itemData.operationVideo"
          ></video>
        </div>
        <div class="info">
          <h1>照片</h1>
          <span v-if="picImgList.length == 0">无</span>
          <div v-else @click="imgagePre" class="img-list">
            <img
              v-for="(item, idx) in picImgList"
              :key="idx"
              :src="item"
              alt=""
              style="width: 1.6rem; height: 1.6rem"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { ImagePreview } from "vant";
export default {
  props: {
    certificateId: String,
    productBatchId: String,
    unitList: Array,
  },
  watch: {},
  data() {
    return {
      list: [],
      show: true,
      itemData: {},
      audio: new Audio(),
      playShow: false,
      picImgList: [],
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    async getDatasc() {
      this.show = true;
      try {
        let params = {
          // certificateId: this.certificateId,
          pageNum: 1,
          pageSize: 10,
        };
        if (this.productBatchId) {
          params.productBatchId = this.productBatchId;
        }
        this.$global.loading.show();
        let response = await cateApi.shengchanxinx(params);
        this.$global.loading.hide();
        let list = response.data.list;
        for (let index = 0; index < this.unitList.length; index++) {
          const element = this.unitList[index];
          for (let lindex = 0; lindex < list.length; lindex++) {
            const lelement = list[lindex];
            if (element.value == lelement.weightUnit) {
              this.$set(lelement, "unitName", element.label);
            }
          }
        }
        this.list = list;
        console.log("list", this.list);
      } catch (error) {
        this.$global.loading.hide();
        console.log(error);
      }
    },
    fromHand(item) {
      if (item.operationPic != "") {
        this.picImgList = item.operationPic.split(",");
      }
      if (item.operationVideo) {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
          this.player.log("onPlayerReady", this);
        });
      }
      this.itemData = item;
      this.show = false;
      // let data = {
      //   item,
      // };
      // let params = JSON.stringify(data);
      // this.$router.push({
      //   path: "/fromDateils",
      //   query: { params },
      // });
    },
    //点击播放音频
    async playHand() {
      await this.$nextTick();
      this.$refs["audio"].play();
      this.playShow = true;
    },
    overAudio() {
      this.playShow = false;
    },
    //点击暂停
    suspendHand() {
      this.$refs["audio"].pause();
      this.playShow = false;
    },
    imgagePre() {
      console.log(this.picImgList);
      ImagePreview(this.picImgList);
    },
  },
};
</script>

<style lang="scss" scoped>
.mains-con {
  width: 100%;
  // height: 460px;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  // overflow: scroll;
  .list-item {
    background-color: #fff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    .item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 0.2rem;
      span {
        &:nth-child(1) {
          font-weight: 600;
        }
        &:nth-child(2) {
          color: #33bd7d;
          font-size: 0.24rem;
          font-weight: 600;
        }
      }
    }
    .info {
      line-height: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0.192rem;
      h1 {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          color: #333;
          flex: 1;
          font-weight: 600;
          //    padding: 0.08rem 0.28rem;
          // background: #f4f8f4;
        }
      }
    }
  }
}
</style>
