<template>
  <div class="main-con">
    <div v-for="item in list" :key="item.certificateId" class="list-item">
      <div class="item-row">
        <span>追溯信息</span>
        <span>以下信息由主体提供</span>
      </div>

      <div class="info">
        <h1>创建人</h1>
        <h1>{{ item.subjectName }}</h1>
      </div>
      <div class="info">
        <h1>创建时间</h1>
        <h1>{{ item.createTime }}</h1>
      </div>
      <div class="info">
        <h1>产品名称</h1>
        <h1>{{ item.productName }}</h1>
      </div>
      <div class="info">
        <h1>产品重量</h1>
        <h1>
          {{ item.number }}{{ item.unitName }}*{{ item.unitWeight
          }}{{ item.weightUnitName }}
        </h1>
      </div>
      <div class="info">
        <h1>所属地区</h1>
        <h1>{{ item.fullRegionName }}</h1>
      </div>
    </div>
    <div v-if="nationalCode" class="code-con">
      <a :href="nationalCode">
        <van-button size="small" type="primary">国家溯源</van-button>
      </a>
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
export default {
  props: {
    certificateId: String,
    subjectId: String,
    unitList: Array,
    weigntunitList: Array,
    nationalCode: String,
  },
  watch: {},
  data() {
    return {
      list: [],
      isGjsyShow: true,
    };
  },
  async mounted() {
    if (this.certificateId) {
      try {
        let params = {
          // subjectId: this.subjectId,
          certificateId: this.certificateId,
          pageNum: 1,
          pageSize: 20,
        };
        this.$global.loading.show();
        let response = await cateApi.zhusxinx(params);
        this.$global.loading.hide();
        let list = response.data.list;
        for (let lindex = 0; lindex < list.length; lindex++) {
          const lelement = list[lindex];
          for (let index = 0; index < this.unitList.length; index++) {
            const element = this.unitList[index];
            if (element.value == lelement.unit) {
              this.$set(lelement, "unitName", element.label);
            }
          }
          for (let index = 0; index < this.weigntunitList.length; index++) {
            const element = this.weigntunitList[index];
            if (element.value == lelement.weightUnit) {
              this.$set(lelement, "weightUnitName", element.label);
            }
          }
        }

        this.list = list;
      } catch (error) {
        this.$global.loading.hide();
        console.log(error);
      }
    }
  },
  methods: {
    gjsyHand() {},
  },
};
</script>

<style lang="scss" scoped>
.main-con {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  // overflow: scroll;
  .list-item {
    background-color: #fff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    .item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 0.2rem;
      span {
        &:nth-child(1) {
          font-weight: 600;
        }
        &:nth-child(2) {
          color: #33bd7d;
          font-size: 0.24rem;
          font-weight: 600;
        }
      }
    }
    .info {
      line-height: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      h1 {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          color: #333;
          flex: 1;
          font-weight: 600;
        }
      }
    }
  }
  .code-con {
    position: absolute;
    right: 0;
    top: 80%;
    transform: translateY(-20%);
  }
}
</style>
