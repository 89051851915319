<template>
  <div class="mains-con">
    <div v-for="item in list" :key="item.id" class="list-item">
      <div class="item-row">
        <span>检测记录</span>
        <span>以下信息由主体提供</span>
      </div>
      <div class="info">
        <h1>检测机构</h1>
        <h1>{{ item.checkOrg }}</h1>
      </div>
      <div class="info">
        <h1>检测时间</h1>
        <h1>{{ item.createTime }}</h1>
      </div>
      <div class="info">
        <h1>检测类型</h1>
        <h1 v-if="item.checkType == 1">企业自检</h1>
        <h1 v-if="item.checkType == 2">机构抽检</h1>
        <h1 v-if="item.checkType == 3">第三方检测</h1>
        <h1 v-if="item.checkType == 4">机构抽检-巡查</h1>
      </div>
      <div class="info">
        <h1>检测人员</h1>
        <h1>{{ item.checkWorker }}</h1>
      </div>
      <div class="info">
        <h1>检测产品</h1>
        <h1>{{ item.productName }}</h1>
      </div>
      <div v-if="item.checkValue" class="info">
        <h1>检测值</h1>
        <h1>{{ item.checkValue }}</h1>
      </div>
      <div class="info">
        <h1>检测结果</h1>
        <h1 v-if="item.result == 1" class="result">合格</h1>
        <h1 v-if="item.result == 2" class="result">不合格</h1>
      </div>
      <!-- <div>
        <img src="" alt="" />
      </div> -->
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
export default {
  props: {
    productBatchId: String,
    certificateId: String,
  },
  watch: {},
  data() {
    return {
      list: [],
    };
  },
  async mounted() {
    try {
      let params = {
        // certificateId: this.certificateId,
        pageNum: 1,
        pageSize: 20,
      };
      if (this.productBatchId) {
        params.productBatchId = this.productBatchId;
      }
      this.$global.loading.show();
      let response = await cateApi.jianche(params);
      this.$global.loading.hide();
      let list = response.data.list;
      this.list = list;
    } catch (error) {
      this.$global.loading.hide();
      console.log(error);
      this.$toast(error.msg);
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mains-con {
  width: 100%;
  // height: 460px;
  height: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  // overflow: scroll;
  .list-item {
    background-color: #fff;
    border-radius: 0.16rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    .item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 0.2rem;
      span {
        &:nth-child(1) {
          font-weight: 600;
        }
        &:nth-child(2) {
          color: #33bd7d;
          font-size: 0.24rem;
          font-weight: 600;
        }
      }
    }
    .info {
      line-height: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      h1 {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          color: #333;
          flex: 1;
          font-weight: 600;
        }
      }
      .result {
        color: #33bd7d !important;
      }
    }
  }
}
</style>
